<template>
  <span class="add-activity-date-modal" v-if="canAdd">
    <b-modal
      dialog-class="modal-lg"
      :id="modalId"
      @ok.prevent="onSave"
      ok-variant="primary"
      cancel-title="Annuler"
      :ok-title="editEvent ? 'Enregistrer' : 'Ajouter'"
      :ok-disabled="!isValid()"
      @shown="init()"
    >
      <template v-slot:modal-title>
        <b>
          <i class="fas fa-calendar-plus"></i>&nbsp;
          <span v-if="editEvent">Modifier une date</span>
          <span v-else>Ajout d'une date</span>
        </b>
      </template>
      <div v-if="error" class="errors-message">
        {{ error }}
      </div>
      <div v-else-if="getValidText()" class="errors-message">
        {{ getValidText() }}
      </div>
      <div v-else class="status-message">
      </div>
      <b-row>
        <b-col cols="8">
          <b-form-group
            label="Début"
            label-for="start-datetime"
          >
            <datetime-input id="start-datetime" v-model="startDateTime"></datetime-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Fin"
            label-for="end-datetime"
          >
            <datetime-input
              id="end-datetime" v-model="endDateTime" :hide-date="true"
            ></datetime-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label-for="eventKind"
            label="Type d'événement"
          >
            <b-select id="eventKind" v-model="eventKind" :disabled="!canSelectKind">
              <b-select-option :value="1">Activité</b-select-option>
              <b-select-option :value="3">Utilisateurs</b-select-option>
              <b-select-option :value="4">Liste d'utilisateurs</b-select-option>
              <b-select-option :value="2">Autres</b-select-option>
            </b-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="eventKind === 1">
        <b-col cols="3">
          <b-form-group
            label-for="schoolYear"
            label="Saison"
          >
            <b-select
              v-model="selectedSchoolYear"
              :disabled="schoolYears.length <= 1"
              name="schoolYear"
            >
              <b-select-option v-for="schoolYear of schoolYears" :key="schoolYear.id" :value="schoolYear">
                {{ schoolYear.name }}
              </b-select-option>
            </b-select>
          </b-form-group>
        </b-col>
        <b-col cols="9">
          <b-form-group
            label-for="activity"
            label="Activité"
          >
            <b-select
              v-model="selectedActivity"
              :disabled="!canSelectActivity"
              name="selectedActivity"
            >
              <b-select-option
                v-for="activity of schoolYearActivities"
                :key="activity.id"
                :value="activity"
              >
                {{ activity.name }}
              </b-select-option>
            </b-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="eventKind === 2">
        <b-col cols="12">
          <b-form-group label="Libellé" label-for="label">
            <b-form-input id="label" v-model="label">
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="eventKind === 3">
        <b-col cols="12">
          <b-form-group label="" label-for="entityId">
            <entity-typeahead
              id="entityId"
              @change="onEntityChanged"
              @init="onEntityChanged"
              :initial-id="entityId"
              :disabled="!!(entity && entity.id)"
            >
            </entity-typeahead>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="eventKind === 4">
        <b-col>
          <b-form-group
            label-for="tag"
            label="Liste d'utilisateurs"
          >
            <b-select
              id="tag"
              v-model="tagId"
              name="tag"
              :disabled="!!(tag && tag.id)"
            >
              <b-select-option v-for="itm of tags" :key="itm.id" :value="itm.id">
                {{ itm.name }}
              </b-select-option>
            </b-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group
            label-for="place"
            label="Lieu"
          >
            <b-select
              v-model="selectedPlace"
              name="selectedPlace"
              :disabled="allPlaces.length <= 1"
            >
              <b-select-option v-for="place of allPlaces" :key="place.id" :value="place">
                {{ place.name }}
              </b-select-option>
            </b-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="editEvent">
        <b-col>
          <b-form-group
            label-for="absence"
            description="Cochez pour indiquer une séance non faîte. Le créneau redevient disponible"
          >
            <b-checkbox v-model="editEvent.absence" id="absence">
              Absence
            </b-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label-for="comments"
            label="Commentaires"
            description="max. 100 caractères"
          >
            <b-form-input type="text" v-model="comments" id="comments" maxlength="100">
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="error">
        <b-col>
          <b-form-group
            label-for="ignoreConflict"
            label="Forçage"
            description="si coché, la séance peut-être crée même si la salle est déjà utilisée à ce moment"
          >
            <b-form-checkbox id="ignoreIfExist" v-model="ignoreConflict">
              Ignorer les conflits
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <div v-if="eventKind === 2"  class="participations">
        <b-row>
          <b-col cols="5">
            <b-form-checkbox id="showParticipations" v-model="showParticipations">
              <b>Saisir les participations</b>
            </b-form-checkbox>
          </b-col>
          <b-col cols="3" class="text-right">
            <div v-if="showParticipations" class="help-text2" style="margin-top: 10px;">Type d'évènement</div>
          </b-col>
          <b-col cols="3">
            <b-form-select
              v-if="showParticipations"
              id="showParticipations"
              name="showParticipations"
              v-model="dateTypeId"
              class="small"
            >
              <b-select-option :value="elt.id" v-for="elt of dateTypes" :key="elt.id">
                {{ elt.name }}
              </b-select-option>
            </b-form-select>
          </b-col>
        </b-row>
        <div v-if="showParticipations && participation">
          <div v-if="dateTypeId === 0" class="participation-line" >
            <b-row>
              <b-col cols="11">
                <div class="help-text2">
                  Un type d'évènement permet de définir des catégories de participants afin de comparer
                  les participations entre des événements de même type.
                  Une fois un type créé, les categories de participants ne sont plus modifiables
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="3"><b>Nom du type d'évènement</b></b-col>
              <b-col cols="8">
                <b-input type="text" v-model="newDateType" name="newDateType">
                </b-input>
              </b-col>
            </b-row>
          </div>
          <div class="participation-line" v-for="elt of participation.categories" :key="elt.id">
            <b-row>
              <b-col cols="8">
                <b-form-input
                  type="text" :disabled="elt.id > 0" v-model="elt.name"
                  placeholder="nom de la catégorie de participants"
                >
                </b-form-input>
              </b-col>
              <b-col cols="3">
                <b-form-input type="number" step="1" min="0" v-model="elt.participation">
                </b-form-input>
              </b-col>
              <b-col cols="1" class="text-right" v-if="participation.id === 0">
                <a href @click.prevent="removeParticipation(elt)">
                  <i class="fa fa-trash"></i>
                </a>
              </b-col>
            </b-row>
          </div>
          <b-row v-if="participation.id === 0">
            <b-col>
              <a href @click.prevent="addParticipation" class="small-text">
                <i class="fa fa-plus"></i> ajouter une nouvelle catégorie de participants
              </a>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-modal>
  </span>
</template>

<script>
import moment from 'moment'
import { mapActions } from 'vuex'
import DatetimeInput from '@/components/Controls/DatetimeInput'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import { makePlace } from '@/types/base'
import { makeActivity } from '@/types/activities'
import { compareDates, compareNumbers } from '@/utils/sorting'
import { addMinutes, compareTimes } from '@/utils/time'
import EntityTypeahead from '@/components/Entity/EntityTypeahead.vue'
import { makeTag } from '@/types/people'
import { makeAgendaDateType } from '@/types/agenda'

export default {
  name: 'add-activity-date-modal',
  components: { EntityTypeahead, DatetimeInput, },
  mixins: [BackendMixin],
  props: {
    modalId: String,
    places: Array,
    activity: {
      type: Object,
      default: null,
    },
    entity: {
      type: Object,
      default: null,
    },
    tag: {
      type: Object,
      default: null,
    },
    initDateTime: {
      type: Object,
      default: null,
    },
    editEvent: Object,
    participations: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      label: '',
      allPlaces: [],
      activities: [],
      schoolYears: [],
      selectedSchoolYear: null,
      selectedActivity: null,
      startDateTime: null,
      endDateTime: null,
      selectedPlace: null,
      error: '',
      comments: '',
      eventKind: 1,
      entityId: 0,
      tagId: 0,
      tags: [],
      tagsLoaded: false,
      ignoreConflict: false,
      participation: null,
      dateTypeId: 0,
      dateTypes: [],
      showParticipations: false,
      nextParticipationId: 0,
      newDateType: '',
    }
  },
  computed: {
    canAdd() {
      return this.hasPerm('agenda.add_activitydate')
    },
    schoolYearActivities() {
      let emptyActivities = []
      if (!this.activity) {
        emptyActivities = [makeActivity()]
      }
      return emptyActivities.concat(
        this.activities.filter(elt => elt.schoolYear.id === this.selectedSchoolYear.id)
      )
    },
    canSelectActivity() {
      return !(
        (this.schoolYearActivities.length <= 1) ||
        (this.editEvent && this.editEvent.activity)
      )
    },
    canSelectKind() {
      return !(
        this.activity || this.entity || this.tag
      )
    },
  },
  watch: {
    startDateTime: function() {
      let delta = 1
      if (this.activity && this.activity.duration) {
        delta = (+this.activity.duration) || 1
      }
      if (this.startDateTime && this.endDateTime) {
        const startDate = moment(this.startDateTime).format('YYYY-MM-DD')
        const startTime = moment(this.startDateTime).format('HH:mm')
        let endTime = moment(this.endDateTime).format('HH:mm')
        if (compareTimes(startTime, endTime) <= 0) {
          endTime = addMinutes(startTime, 60 * delta)
        }
        this.endDateTime = startDate + 'T' + endTime
      }
      if (this.startDateTime && !this.endDateTime) {
        const startDate = moment(this.startDateTime).format('YYYY-MM-DD')
        const startTime = moment(this.startDateTime).format('HH:mm')
        const endTime = addMinutes(startTime, 60 * delta)
        this.endDateTime = startDate + 'T' + endTime
      }
    },
    schoolYearActivities: function() {
      if (this.schoolYearActivities.length === 1) {
        this.selectedActivity = this.schoolYearActivities[0]
      }
    },
    editEvent: function() {
      // this.init()
    },
    initDateTime: function() {
      // this.init()
    },
    eventKind: function() {
      if (this.eventKind === 4) {
        this.loadTags()
      }
    },
    showParticipations: async function() {
      if (this.showParticipations) {
        this.dateTypeId = 0
        this.newDateType = ''
        if (this.dateTypes.length === 0) {
          await this.loadDateTypes()
        }
        await this.loadParticipations()
      } else {
        this.participation = null
      }
    },
    dateTypeId: async function() {
      if (this.editEvent && this.editEvent.dateTypeId === this.dateTypeId) {
        await this.loadParticipations()
      } else {
        // Le type d'évènement sélectionné est différent de celui actuel
        const selectedTypeIndex = this.dateTypes.map(elt => elt.id).indexOf(this.dateTypeId)
        if (selectedTypeIndex >= 0) {
          this.participation = this.dateTypes[selectedTypeIndex]
        }
        if (this.participation && this.participation.categories.length === 0) {
          this.addParticipation()
        }
      }
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    async init() {
      this.label = ''
      this.error = ''
      if (!this.places) {
        await this.loadAllPlaces()
      } else {
        this.allPlaces = this.places
      }
      if (!this.activity) {
        await this.loadActivities()
        this.selectedActivity = this.schoolYearActivities[0]
      } else {
        this.activities = [this.activity]
        this.schoolYears = [this.activity.schoolYear]
        this.selectedSchoolYear = this.schoolYears[0]
        this.selectedActivity = this.activities[0]
      }
      if (this.editEvent) {
        if (this.editEvent.place) {
          const places = this.allPlaces.filter(elt => elt.id === this.editEvent.place.id)
          if (places.length > 0) {
            this.selectedPlace = places[0]
          }
        }
        if (this.editEvent.activity && this.editEvent.activity.id) {
          const activities = this.activities.filter(elt => elt.id === this.editEvent.activity.id)
          if (activities.length > 0) {
            this.selectedActivity = activities[0]
            const schoolYears = this.schoolYears.filter(elt => elt.id === this.selectedActivity.schoolYear.id)
            if (schoolYears.length > 0) {
              this.selectedSchoolYear = schoolYears[0]
            }
            this.eventKind = 1
            this.entityId = 0
            this.tagId = 0
            this.label = ''
          }
        } else if (this.editEvent.entity) {
          this.entityId = this.editEvent.entity.id
          this.label = ''
          this.tagId = 0
          this.eventKind = 3
        } else if (this.editEvent.tag) {
          this.entityId = 0
          this.tagId = this.editEvent.tag.id
          this.label = ''
          this.eventKind = 4
        } else {
          this.entityId = 0
          this.tagId = 0
          this.label = this.editEvent.label || ''
          this.eventKind = 2
        }
        this.startDateTime = moment(this.editEvent.startDateTime).format('YYYY-MM-DD HH:mm')
        this.endDateTime = moment(this.editEvent.endDateTime).format('YYYY-MM-DD HH:mm')
        this.comments = this.editEvent.comments
        this.dateTypeId = this.editEvent.dateTypeId
        if (this.dateTypeId) {
          this.showParticipations = true
          await this.loadParticipations()
        } else {
          this.showParticipations = false
        }
      } else {
        this.startDateTime = null
        this.endDateTime = null
        this.showParticipations = this.participations
        this.dateTypeId = 0
        this.newDateType = ''
        if (this.allPlaces.length === 1) {
          this.selectedPlace = this.allPlaces[0]
        }
        if (this.initDateTime) {
          this.startDateTime = moment(this.initDateTime).format('YYYY-MM-DD HH:mm')
          this.endDateTime = this.startDateTime
        }
        if (this.activity && this.activity.id) {
          this.activities = [this.activity]
          this.selectedActivity = this.activities[0]
          this.schoolYears = [this.activity.schoolYear]
          this.selectedSchoolYear = this.schoolYears[0]
          this.eventKind = 1
        } else if (this.entity && this.entity.id) {
          this.entityId = this.entity.id
          this.label = ''
          this.tagId = 0
          this.eventKind = 3
        } else if (this.tag && this.tag.id) {
          this.entityId = 0
          this.tagId = this.tag.id
          this.label = ''
          this.eventKind = 4
        } else {
          this.entityId = 0
          this.tagId = 0
          this.label = ''
          this.eventKind = 2
        }
        this.comments = ''
        this.dateTypeId = 0
      }
      this.ignoreConflict = false
    },
    onEntityChanged(event) {
      this.entityId = event.entity ? event.entity.id : 0
    },
    async loadTags() {
      if (!this.tagsLoaded) {
        this.tags = []
        let url = '/api/people/tags/'
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          this.tags = resp.data.map(makeTag)
          this.tagsLoaded = true
        } catch (err) {
          this.error = this.getErrorText(err)
        }
      }
    },
    async loadParticipations() {
      if (this.editEvent && this.dateTypeId) {
        this.participation = null
        let url = '/agenda/api/agenda-date-participations/' + this.editEvent.id + '/'
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          this.participation = makeAgendaDateType(resp.data)
        } catch (err) {
          this.error = this.getErrorText(err)
        }
      } else {
        this.participation = makeAgendaDateType()
        this.addParticipation()
      }
    },
    async changeParticipations(dateId) {
      let url = '/agenda/api/agenda-date-participations/' + dateId + '/'
      const backendApi = new BackendApi('post', url)
      try {
        const categories = []
        for (const elt of this.participation.categories) {
          categories.push(
            {
              category: elt.id,
              participation: elt.participation,
            }
          )
        }
        await backendApi.callApi({ 'categories': categories, })
      } catch (err) {
        let text1 = 'Une erreur est survenue lors de la sauvegarde des participations'
        await this.addError(text1 + ': ' + this.getErrorText(err))
      }
    },
    async onSave() {
      this.error = ''
      let dateTypeId = this.dateTypeId
      let keepParticipations = []
      if (this.showParticipations) {
        if ((dateTypeId === 0)) {
          // on ajoute un nouveau type
          const catNames = this.participation.categories.map(
            elt => { return { name: elt.name, } }
          )
          keepParticipations = this.participation.categories.map(
            elt => elt.participation
          )
          const data = {
            name: this.newDateType,
            categories: catNames,
          }
          let backendApi = new BackendApi('post', '/agenda/api/agenda-date-types/')
          try {
            const resp = await backendApi.callApi(data)
            const newDataType = makeAgendaDateType(resp.data)
            let dataTypes = this.dateTypes.filter(elt => elt.id > 0)
            dataTypes.push(newDataType)
            this.dateTypes = dataTypes.concat(this.dateTypes.filter(elt => elt.id === 0))
            this.participation = newDataType
            for (
              let keepIndex = 0;
              (keepIndex < keepParticipations.length) && (keepIndex < this.participation.categories.length);
              keepIndex++
            ) {
              this.participation.categories[keepIndex].participation = keepParticipations[keepIndex]
            }
            dateTypeId = resp.data.id
          } catch (err) {
            this.error = this.getErrorText(err)
          }
        }
      } else {
        dateTypeId = null
      }
      if (!this.error) {
        let url = '/agenda/api/activity-dates/'
        if (this.editEvent) {
          url += this.editEvent.id + '/'
        }
        const method = this.editEvent ? 'patch' : 'post'
        const data = {
          activity: this.eventKind === 1 ? this.selectedActivity.id : null,
          label: this.eventKind === 2 ? this.label : '',
          entity: this.eventKind === 3 ? this.entityId : null,
          tag: this.eventKind === 4 ? this.tagId : null,
          start_datetime: moment(this.startDateTime).format('YYYY-MM-DD HH:mm'),
          end_datetime: moment(this.endDateTime).format('YYYY-MM-DD HH:mm'),
          place: this.selectedPlace.id,
          comments: this.comments.substring(0, 100),
          date_type: dateTypeId || null,
        }
        if (this.ignoreConflict) {
          data['ignore_conflict'] = true
        }
        if (this.editEvent) {
          data.absence = this.editEvent.absence
        }
        let backendApi = new BackendApi(method, url)
        try {
          const resp = await backendApi.callApi(data)
          if (this.editEvent) {
            await this.addSuccess('La date a été modifiée')
            this.$emit('updated', resp.data)
          } else {
            await this.addSuccess('La date a été ajoutée')
            this.$emit('done', resp.data)
          }
          if (this.showParticipations) {
            const dateId = resp.data.id
            await this.changeParticipations(dateId)
          }
          this.$bvModal.hide(this.modalId)
        } catch (err) {
          this.error = this.getErrorText(err)
        }
        this.dateTypeId = dateTypeId
      }
    },
    async loadAllPlaces() {
      try {
        let url = '/api/home/places/'
        let backendApi = new BackendApi('get', url)
        let resp = await backendApi.callApi()
        this.allPlaces = resp.data.map(makePlace)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadDateTypes() {
      try {
        let url = '/agenda/api/agenda-date-types/'
        let backendApi = new BackendApi('get', url)
        let resp = await backendApi.callApi()
        this.dateTypes = resp.data.map(makeAgendaDateType).concat(
          makeAgendaDateType({ id: 0, name: 'Nouveau type', })
        )
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadActivities() {
      this.activities = []
      this.schoolYears = []
      let schoolYearIds = []
      let url = '/api/activities/activities/?all=1'
      let backendApi = new BackendApi('get', url)
      try {
        let resp = await backendApi.callApi()
        this.activities = resp.data.map(elt => makeActivity(elt))
        for (let activity of this.activities) {
          const index = schoolYearIds.indexOf(activity.schoolYear.id)
          if (index < 0) {
            schoolYearIds.push(activity.schoolYear.id)
            this.schoolYears.push(activity.schoolYear)
          }
        }
        this.schoolYears = this.schoolYears.sort(
          (year1, year2) => {
            return -compareNumbers(year1.startYear, year2.startYear)
          }
        )
        if (this.schoolYears.length > 0) {
          this.selectedSchoolYear = this.schoolYears[0]
        }
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    getValidText() {
      if (!this.startDateTime || !this.endDateTime) {
        return 'Saisir un début et une fin'
      }

      if (!this.selectedActivity) {
        return 'Activité manquante'
      }

      if ((this.eventKind === 1) && (this.selectedActivity.id === 0)) {
        return 'Choisir une activité'
      }

      if ((this.eventKind === 2) && !this.label) {
        return 'Saisir un libellé'
      }

      if ((this.eventKind === 3) && !this.entityId) {
        return 'Saisir un contact'
      }

      if ((this.eventKind === 4) && !this.tagId) {
        return 'Saisir une liste'
      }

      if (!this.selectedPlace) {
        return 'Saisir un lieu'
      }

      if (compareDates(this.startDateTime, this.endDateTime) >= 0) {
        return 'La fin doit être postérieure au début'
      }
      if (this.showParticipations) {
        if ((this.dateTypeId === 0) && !this.newDateType) {
          return 'Veuillez saisir un nom pour le type de date'
        }
        if (this.participation) {
          if (this.participation.categories.length === 0) {
            return 'Veuillez saisir au moins un type de participant'
          }
          for (const elt of this.participation.categories) {
            if (!elt.name) {
              return 'Veuillez saisir un nom pour le type de participant'
            }
            if (('' + (+elt.participation) !== ('' + elt.participation))) {
              return 'Veuillez saisir un nombre pour le nombre de participant'
            }
          }
        } else {
          return 'Participation invalide'
        }
      }
      return ''
    },
    isValid() {
      return this.getValidText() === ''
    },
    addParticipation() {
      if (this.participation) {
        this.nextParticipationId += -1
        this.participation.categories.push(
          {
            id: this.nextParticipationId,
            name: '',
            order: 0,
            participation: 0,
          }
        )
      }
    },
    removeParticipation(participationElt) {
      if (this.participation) {
        const index = this.participation.categories.map(elt => elt.id).indexOf(participationElt.id)
        if (index >= 0) {
          this.participation.categories.splice(index, 1)
        }
      }
    },
  },
  mounted() {
    // this.init()
  },
}
</script>

<style scoped>
.errors-message {
  background: #f0ad4e;
  padding: 4px 10px;
  font-weight: bold;
}
.status-message {
  background: #f0f0f0;
  padding: 4px 10px;
  min-height: 36px;
}
.participations {
  border: solid 1px #eee;
  margin: 5px 0;
  padding: 5px;
  border-radius: 4px;
  background: #f0f0f0;
}
.participation-line {
  font-size: 12px;
  input {
    font-size: 12px;
  }
  padding: 2px 0;
}
</style>
